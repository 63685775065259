define('ember-calendar/models/component-calendar', ['exports', 'ember', 'ember-calendar/macros/computed-moment', 'ember-calendar/macros/computed-duration', 'ember-calendar/models/calendar', 'ember-calendar/models/occurrence-proxy'], function (exports, _ember, _emberCalendarMacrosComputedMoment, _emberCalendarMacrosComputedDuration, _emberCalendarModelsCalendar, _emberCalendarModelsOccurrenceProxy) {
  'use strict';

  exports['default'] = _emberCalendarModelsCalendar['default'].extend({
    component: null,
    timeZone: _ember['default'].computed.oneWay('component.timeZone'),
    startingTime: (0, _emberCalendarMacrosComputedMoment['default'])('component.startingDate'),
    dayStartingTime: (0, _emberCalendarMacrosComputedDuration['default'])('component.dayStartingTime'),
    dayEndingTime: (0, _emberCalendarMacrosComputedDuration['default'])('component.dayEndingTime'),
    timeSlotDuration: (0, _emberCalendarMacrosComputedDuration['default'])('component.timeSlotDuration'),

    defaultOccurrenceTitle: _ember['default'].computed.oneWay('component.defaultOccurrenceTitle'),

    defaultOccurrenceDuration: (0, _emberCalendarMacrosComputedDuration['default'])('component.defaultOccurrenceDuration'),

    occurrences: _ember['default'].computed('component.occurrences.[]', function () {
      var _this = this;

      return this.get('component.occurrences').map(function (occurrence) {
        return _emberCalendarModelsOccurrenceProxy['default'].create({ calendar: _this, content: occurrence });
      });
    })
  });
});