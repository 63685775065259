define('ember-href-to/lib/container-lookup', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports.containerLookup = containerLookup;
  exports.getRouter = getRouter;

  var application;

  function getApplication() {
    var apps = _ember['default'].Namespace.NAMESPACES.filter(function (namespace) {
      return namespace instanceof _ember['default'].Application;
    });

    return apps[0];
  }

  function containerLookup(key) {
    if (application === undefined || application.testing) {
      application = getApplication();
    }

    return application.__container__.lookup(key);
  }

  function getRouter() {
    return containerLookup('router:main');
  }

  exports['default'] = containerLookup;
});