define('ember-shepherd/services/tour', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var Evented = _ember['default'].Evented;
  var Service = _ember['default'].Service;
  var computed = _ember['default'].computed;
  var isPresent = _ember['default'].isPresent;
  var on = _ember['default'].on;
  var run = _ember['default'].run;
  var $ = _ember['default'].$;
  var isEmpty = _ember['default'].isEmpty;
  var scheduleOnce = run.scheduleOnce;
  var oneWay = computed.oneWay;

  /**
   * Taken from introjs https://github.com/usablica/intro.js/blob/master/intro.js#L1092-1124
   * Get an element position on the page
   * Thanks to `meouw`: http://stackoverflow.com/a/442474/375966
   */
  function getElementPosition(element) {
    var elementPosition = {};
    elementPosition.width = element.offsetWidth;
    elementPosition.height = element.offsetHeight;

    //calculate element top and left
    var x = 0;
    var y = 0;
    while (element && !isNaN(element.offsetLeft) && !isNaN(element.offsetTop)) {
      x += element.offsetLeft;
      y += element.offsetTop;
      element = element.offsetParent;
    }

    elementPosition.top = y;
    elementPosition.left = x;
    return elementPosition;
  }

  exports['default'] = Service.extend(Evented, {
    applicationController: null,
    // Get current path
    currentPath: oneWay('applicationController.currentPath'),

    // Configuration Options
    defaults: {},

    disableScroll: false,

    errorTitle: null,

    // Is the tour currently running?
    isActive: false,

    messageForUser: null,

    modal: false,

    requiredElements: _ember['default'].A(),

    steps: _ember['default'].A(),

    initialize: on('init', function () {
      var _this = this;

      // Set up event bindings
      this.on('start', function () {
        // What else has to be run on start?
        scheduleOnce('afterRender', _this, function () {
          if (isPresent(this.get('tourObject'))) {
            this.get('tourObject').start();
          }
          this.set('isActive', true);
        });
      });
      this.on('complete', function () {
        // What else has to be run on completion?
        _this.set('isActive', false);
      });
      this.on('cancel', function () {
        _this.cleanupModalLeftovers();
        _this.get('tourObject').cancel();
        _this.set('isActive', false);
      });
      this.on('next', function () {
        //Re-enable clicking on the element
        var currentStepElement = _this.getElementForCurrentStep();
        if (currentStepElement) {
          currentStepElement.style.pointerEvents = 'auto';
        }
        _this.get('tourObject').next();
      });
      this.on('back', function () {
        //Re-enable clicking on the element
        var currentStepElement = _this.getElementForCurrentStep();
        if (currentStepElement) {
          currentStepElement.style.pointerEvents = 'auto';
        }
        _this.get('tourObject').back();
      });
    }),

    /**
     * Checks the builtInButtons array for the step and adds a button with the correct action for the type
     * @param step The step to add the buttons to
     * @param shepherdStepOptions The options array to modify
     * @private
     */
    addBuiltInButtons: function addBuiltInButtons(step, shepherdStepOptions) {
      var _this2 = this;

      step.options.builtInButtons.forEach(function (button) {
        var action = undefined;
        if (['back', 'next', 'cancel'].indexOf(button.type) > -1) {
          action = function () {
            return _this2.trigger(button.type);
          };
        } else {
          action = button.action || _ember['default'].K;
        }

        shepherdStepOptions.buttons.push({
          classes: button.classes,
          text: button.text,
          action: action
        });
      });
    },

    /**
     * Cleanup the modal leftovers, like the overlay and highlight, so they don't hang around.
     * @private
     */
    cleanupModalLeftovers: function cleanupModalLeftovers() {
      if (this.get('modal')) {
        var tour = this.get('tour');
        if (tour) {
          var currentStep = tour.getCurrentStep();
          if (currentStep && currentStep.options.attachTo && this.getElementForStep(currentStep)) {
            this.getElementForStep(currentStep).style.pointerEvents = 'auto';
          }
        }
        run('afterRender', function () {
          $('#shepherdOverlay').remove();
          $('#highlightOverlay').remove();
          $('.shepherd-modal').removeClass('shepherd-modal');
        });
      }
    },

    /**
     * Creates an overlay element clone of the element you want to highlight and copies all the styles.
     * @param step The step object that points to the element to highlight
     */
    createHighlightOverlay: function createHighlightOverlay(step) {
      $('#highlightOverlay').remove();
      var currentElement = this.getElementForStep(step);
      if (currentElement) {
        var elementPosition = getElementPosition(currentElement);
        var highlightElement = $(currentElement).clone();
        highlightElement.attr('id', 'highlightOverlay');
        $('body').append(highlightElement);
        var computedStyle = window.getComputedStyle(currentElement).cssText;
        highlightElement[0].style.cssText = computedStyle;
        //Style all internal elements as well
        var children = $(currentElement).children();
        var clonedChildren = highlightElement.children();
        for (var i = 0; i < children.length; i++) {
          clonedChildren[i].style.cssText = window.getComputedStyle(children[i]).cssText;
        }
        highlightElement.css('position', 'absolute');
        highlightElement.css('left', elementPosition.left);
        highlightElement.css('top', elementPosition.top);
        highlightElement.css('width', elementPosition.width);
        highlightElement.css('height', elementPosition.height);
        highlightElement.css('z-index', '10002');
      }
    },

    /**
     * Exchanges the given attachment configuration for the object equivalent with
     * the DOM element passed in directly.
     *
     * Allows for use of pseudoselectors, because it uses jQuery's selector engine
     * instead of the built-in one
     *
     * @param {Object} attachTo The given "attachTo" configuration
     * @return {Object} The optimized configuration
     * @private
     */
    exchangeForAttachmentConfig: function exchangeForAttachmentConfig(attachTo) {
      var type = typeof attachTo;
      var config = {
        element: null,
        on: null
      };
      if (type === 'string') {
        config.element = this.getElementFromString(attachTo);
        var configArray = attachTo.split(' ');
        config.on = configArray[configArray.length - 1];
      } else if (attachTo !== null && attachTo !== undefined && type === 'object') {
        config.element = this.getElementFromObject(attachTo);
        config.on = attachTo.on;
      } else {
        config = null;
      }

      return config;
    },

    /**
     * Return the element for the current step
     *
     * @method getElementForCurrentStep
     * @returns {Element} the element for the current step
     */
    getElementForCurrentStep: function getElementForCurrentStep() {
      var currentStep = this.get('tourObject').getCurrentStep();
      return this.getElementForStep(currentStep);
    },

    /**
     * Return the element for a step
     *
     * @method getElementForStep
     * @param step step the step to get an element for
     * @returns {Element} the element for this step
     * @private
     */
    getElementForStep: function getElementForStep(step) {
      var attachTo = step.options.attachTo;
      var type = typeof attachTo;
      var element = undefined;
      if (type === 'string') {
        element = this.getElementFromString(attachTo);
      } else if (attachTo !== null && attachTo !== undefined && type === 'object') {
        element = this.getElementFromObject(attachTo);
      } else {
        element = null;
      }
      return element;
    },

    /**
     * Get the element from an option object
     *
     * @method getElementFromObject
     * @param Object attachTo
     * @returns {Element}
     * @private
     */
    getElementFromObject: function getElementFromObject(attachTo) {
      var op = attachTo.element;
      return $(op).get(0);
    },

    /**
     * Get the element from an option string
     *
     * @method getElementFromString
     * @param string element the string in the step configuration
     * @returns {Element} the element from the string
     * @private
     */
    getElementFromString: function getElementFromString(element) {
      var attachTo = element.split(' ');
      attachTo.pop();
      var selector = attachTo.join(' ');
      return $(selector).get(0);
    },

    /**
     * Increases the z-index of the element, to pop it out above the overlay and highlight it
     * @param step The step object that attaches to the element
     * @private
     */
    popoutElement: function popoutElement(step) {
      $('.shepherd-modal').removeClass('shepherd-modal');
      var currentElement = this.getElementForStep(step);
      if (currentElement) {
        $(currentElement).addClass('shepherd-modal');
        if (step.options.highlightClass) {
          $(currentElement).addClass(step.options.highlightClass);
        }
      }
    },

    /**
     * Create a tour object based on the current configuration
     */
    tourObject: computed('steps', 'default', 'requiredElements', function () {
      var _this3 = this;

      var steps = this.get('steps');

      // Return nothing if there are no steps
      if (isEmpty(steps)) {
        return;
      }

      // Create a new tour object with the new defaults
      var tour = new Shepherd.Tour({
        defaults: this.get('defaults')
      });

      // Check for the required elements and set up the steps on the tour
      if (this.requiredElementsPresent()) {
        steps.forEach(function (step, index) {
          var shepherdStepOptions = { buttons: [] };
          for (var option in step.options) {
            if (option === 'builtInButtons') {
              _this3.addBuiltInButtons(step, shepherdStepOptions);
            } else if (option === 'attachTo') {
              shepherdStepOptions[option] = _this3.exchangeForAttachmentConfig(step.options[option]);
            } else {
              shepherdStepOptions[option] = step.options[option];
            }
          }
          tour.addStep(step.id, shepherdStepOptions);

          // Step up events for the current step
          var currentStep = tour.steps[index];
          currentStep.on('before-show', function () {
            if (_this3.get('modal')) {
              var currentElement = _this3.getElementForStep(currentStep);
              if (currentElement) {
                currentElement.style.pointerEvents = 'none';
                if (currentStep.options.copyStyles) {
                  _this3.createHighlightOverlay(currentStep);
                } else {
                  _this3.popoutElement(currentStep);
                }
              }
            }
          });
          currentStep.on('hide', function () {
            //Remove element copy, if it was cloned
            var currentElement = _this3.getElementForStep(currentStep);
            if (currentElement) {
              if (currentStep.options.highlightClass) {
                $(currentElement).removeClass(currentStep.options.highlightClass);
              }
              $('#highlightOverlay').remove();
            }
          });
        });
      } else {
        tour.addStep('error', {
          buttons: [{
            text: 'Exit',
            action: tour.cancel
          }],
          classes: 'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text',
          copyStyles: false,
          title: this.get('errorTitle'),
          text: [this.get('messageForUser')]
        });
      }

      // Set up tour event bindings
      tour.on('start', function () {
        if (_this3.get('modal')) {
          $('body').append('<div id="shepherdOverlay"> </div>');
        }
        if (_this3.get('disableScroll')) {
          $(window).disablescroll();
        }
      });

      tour.on('complete', function () {
        _this3.cleanupModalLeftovers();
        if (_this3.get('disableScroll')) {
          $(window).disablescroll('undo');
        }
        _this3.trigger('complete');
      });
      tour.on('cancel', function () {
        _this3.cleanupModalLeftovers();
        if (_this3.get('disableScroll')) {
          $(window).disablescroll('undo');
        }
      });

      // Return the created tour object
      return tour;
    }),

    /**
     * Observes the array of requiredElements, which are the elements that must be present at the start of the tour,
     * and determines if they exist, and are visible, if either is false, it will stop the tour from executing.
     * @private
     */
    requiredElementsPresent: function requiredElementsPresent() {
      var _this4 = this;

      var allElementsPresent = true;
      var requiredElements = this.get('requiredElements');
      if (isPresent(requiredElements)) {
        requiredElements.forEach(function (element) {
          if (allElementsPresent && (!$(element.selector)[0] || !$(element.selector).is(':visible'))) {
            allElementsPresent = false;
            _this4.set('errorTitle', element.title);
            _this4.set('messageForUser', element.message);
          }
        });
      }
      return allElementsPresent;
    }

  });
});