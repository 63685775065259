define('dlx-range-slider/components/dlx-range-slider', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['range-slider'],

    didRender: function didRender() {
      var self = this;

      var config = this.get('config');
      var min = this.get('min');
      var max = this.get('max');

      var localConfig = _ember['default'].$.extend(true, {}, {
        min: min,
        max: max,
        start: [min, max]
      }, config);

      this.$().noUiSlider(localConfig).on('slide', function (e, vals) {
        self.setProperties({
          min: vals[0],
          max: vals[1]
        });
      });
    }
  });
});