define('ember-cli-fetch-async-button/components/fetch-async-button', ['exports', 'ember', 'ember-cli-fetch-async-button/templates/components/fetch-async-button'], function (exports, _ember, _emberCliFetchAsyncButtonTemplatesComponentsFetchAsyncButton) {
  'use strict';

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var getWithDefault = _ember['default'].getWithDefault;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var run = _ember['default'].run;

  exports['default'] = _ember['default'].Component.extend({
    layout: _emberCliFetchAsyncButtonTemplatesComponentsFetchAsyncButton['default'],
    tagName: 'button',
    textState: 'default',
    reset: false,
    classNames: ['async-button'],
    classNameBindings: ['textState'],
    attributeBindings: ['disableWhen', 'disabled', 'type', '_href:href', 'tabindex'],
    type: 'submit',

    disabled: _ember['default'].computed('textState', 'disableWhen', function () {
      var textState = get(this, 'textState');
      var disableWhen = get(this, 'disableWhen');
      return disableWhen || textState === 'pending';
    }),

    text: _ember['default'].computed('textState', 'default', 'pending', 'resolved', 'fulfilled', 'rejected', function () {
      return getWithDefault(this, this.textState, get(this, 'default'));
    }),

    _isError: function _isError(code) {
      return !(code >= 200 && code < 300);
    },

    click: function click() {
      var _attrs,
          _this = this;

      var params = this.getWithDefault('params', []);

      set(this, 'textState', 'pending');

      (_attrs = this.attrs).asyncAction.apply(_attrs, [function (promise) {
        if (!promise) {
          return;
        }

        promise.then(function (status) {
          if (status instanceof Array) {
            // array of promises
            status.forEach(function (s) {
              if (_this._isError(s.status)) {
                throw error;
              }
            });

            return status;
          }

          if (status instanceof Object) {
            // hash of promises
            Object.keys(status).forEach(function (k) {
              if (_this._isError(status[k].status)) {
                throw status;
              }
            });

            return status;
          }

          if (!_this._isError(status.status)) {
            return status;
          } else {
            throw status;
          }
        }).then(function () {
          if (!_this.isDestroyed) {
            set(_this, 'textState', 'fulfilled');
          }
        })['catch'](function () {
          if (!_this.isDestroyed) {
            set(_this, 'textState', 'rejected');
            run.later(function () {
              return set(_this, 'textState', 'default');
            }, 3000);
          }
        });
      }].concat(_toConsumableArray(params)));

      // If this is part of a form, it will perform an HTML form
      // submission without returning false to prevent action bubbling
      return false;
    }
  });
});