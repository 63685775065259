define('ember-calendar/components/as-calendar/time-zone-select', ['exports', 'moment', 'ember', 'ember-calendar/models/time-zone-option'], function (exports, _moment, _ember, _emberCalendarModelsTimeZoneOption) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: [':as-calendar-time-zone-select', 'showResults:open'],
    tagName: 'section',

    defaultQuery: '',
    showResults: false,
    inputQuery: '',
    query: '',
    value: null,
    options: null,
    showSearch: true,

    selectedOptionAbbreviation: _ember['default'].computed.oneWay('selectedOption.abbreviation'),

    arrangedOptions: _ember['default'].computed('_options.@each.{description,value}', 'selectedOption.value', 'showSearch', '_query', function () {
      var _this = this;

      if (this.get('showSearch')) {
        var regexp = new RegExp(this.get('_query'), 'i');

        return this.get('_options').filter(function (option) {
          return option.get('description').match(regexp) || option.get('value') === _this.get('selectedOption.value');
        });
      } else {
        return this.get('_options');
      }
    }),

    selectedOption: _ember['default'].computed('value', function () {
      return _emberCalendarModelsTimeZoneOption['default'].create({ value: this.get('value') });
    }),

    _query: _ember['default'].computed('query', 'defaultQuery', function () {
      var query = this.get('query');

      if (_ember['default'].isEmpty(query)) {
        return this.get('defaultQuery');
      } else {
        return query;
      }
    }),

    _options: _ember['default'].computed('options', function () {
      if (_ember['default'].isPresent(this.get('options'))) {
        return this.get('options');
      } else {
        return _moment['default'].tz.names().map(function (timeZoneName) {
          return _emberCalendarModelsTimeZoneOption['default'].create({ value: timeZoneName });
        });
      }
    }),

    _setQuery: function _setQuery(value) {
      this.set('query', value);
    },

    actions: {
      inputQueryChanged: function inputQueryChanged(value) {
        _ember['default'].run.debounce(this, this._setQuery, value, 200);
      }
    }
  });
});