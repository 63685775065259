define('ember-component-inbound-actions/inbound-actions', ['exports', 'ember', 'ember-component-inbound-actions/action-proxy'], function (exports, _ember, _emberComponentInboundActionsActionProxy) {
  'use strict';

  exports['default'] = _ember['default'].Mixin.create({
    _inbound_actions_setup: (function () {
      _ember['default'].run.schedule('afterRender', this, function () {
        var proxy = _emberComponentInboundActionsActionProxy['default'].create({ target: this });
        this.set('actionReceiver', proxy);
      });
    }).on('init')
  });
});