define('ember-href-to/helpers/href-to', ['exports', 'ember', 'ember-href-to/lib/container-lookup'], function (exports, _ember, _emberHrefToLibContainerLookup) {
  'use strict';

  exports.hrefTo = hrefTo;

  var appNeedsClickHandler = true;

  function _getNormalisedRootUrl(router) {
    var rootURL = router.rootURL;
    if (rootURL.charAt(rootURL.length - 1) !== '/') {
      rootURL = rootURL + '/';
    }
    return rootURL;
  }

  function _setupClickHandler() {
    _ember['default'].$(document.body).on('click', 'a', function (e) {
      var $target = _ember['default'].$(e.currentTarget);
      var handleClick = e.which === 1 && !e.ctrlKey && !e.metaKey;

      if (handleClick && !$target.hasClass('ember-view') && _ember['default'].isNone($target.attr('data-ember-action'))) {
        var router = (0, _emberHrefToLibContainerLookup.getRouter)();

        var rootURL = _getNormalisedRootUrl(router);
        var url = $target.attr('href');

        if (url && url.indexOf(rootURL) === 0) {
          url = url.substr(rootURL.length - 1);

          if (router.router.recognizer.recognize(url)) {
            router.handleURL(url);
            router.router.updateURL(url);
            return false;
          }
        }
      }
      return true;
    });
  }

  function hrefTo(params) {
    if (appNeedsClickHandler) {
      _setupClickHandler();
      appNeedsClickHandler = false;
    }

    var lastParam = params[params.length - 1];

    var queryParams = {};
    if (lastParam && lastParam.isQueryParams) {
      queryParams = params.pop();
    }

    var targetRouteName = params.shift();

    var args = [targetRouteName];
    args.push.apply(args, params);
    args.push({ queryParams: queryParams.values });

    var router = (0, _emberHrefToLibContainerLookup.getRouter)();
    return router.generate.apply(router, args);
  }

  exports['default'] = _ember['default'].Helper.helper(hrefTo);
});