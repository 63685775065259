define("liquid-fire/modals", ["exports", "ember", "liquid-fire/modal"], function (exports, _ember, _liquidFireModal) {
  "use strict";

  exports["default"] = _ember["default"].Service.extend({
    routing: _ember["default"].inject.service('-routing'),

    setup: _ember["default"].on('init', function () {

      this.set('modalContexts', _ember["default"].A());
      this.set('modals', _ember["default"].A());

      var modalConfigs = this.container.lookup('router:main').router.modals;
      if (modalConfigs && modalConfigs.length > 0) {
        var self = this;
        modalConfigs.forEach(function (m) {
          self.registerModal(m);
        });
      }
    }),

    registerModal: function registerModal(config) {
      var ext = {
        modals: this,
        container: this.container
      };

      for (var param in config.options.withParams) {
        ext[param + "Observer"] = observerForParam(param);
      }

      this.get('modals').pushObject(_liquidFireModal["default"].extend(ext).create(config));
    },

    activeRouteNames: _ember["default"].computed('routing.currentRouteName', function () {
      // We need this to force the right observers to all be in place
      // for invalidation, even though we aren't use it directly.
      this.get('routing.currentRouteName');

      var infos = this.container.lookup('router:main').router.currentHandlerInfos;
      if (infos) {
        return infos.map(function (h) {
          return h.name;
        });
      } else {
        return [];
      }
    })

  });

  function observerForParam(param) {
    return _ember["default"].observer('controller.' + param, function () {
      this.update();
    });
  }
});