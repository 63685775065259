define("ember-cli-uuid/utils/uuid-helpers", ["exports"], function (exports) {
  "use strict";

  exports.uuid = uuid;

  function uuid() {
    return window.uuid.v4();
  }

  exports["default"] = uuid;
});