define('ember-cli-async-button/components/async-button', ['exports', 'ember', 'ember-cli-async-button/templates/components/async-button'], function (exports, _ember, _emberCliAsyncButtonTemplatesComponentsAsyncButton) {
  'use strict';

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var get = _ember['default'].get;
  var getWithDefault = _ember['default'].getWithDefault;
  var set = _ember['default'].set;

  var positionalParams = {
    positionalParams: 'params'
  };

  var ButtonComponent = _ember['default'].Component.extend(positionalParams, {
    layout: _emberCliAsyncButtonTemplatesComponentsAsyncButton['default'],
    tagName: 'button',
    textState: 'default',
    reset: false,
    classNames: ['async-button'],
    classNameBindings: ['textState'],
    attributeBindings: ['disableWhen', 'disabled', 'type', '_href:href', 'tabindex'],

    type: 'submit',
    disabled: _ember['default'].computed('textState', 'disableWhen', function () {
      var textState = get(this, 'textState');
      var disableWhen = get(this, 'disableWhen');
      return disableWhen || textState === 'pending';
    }),

    click: function click() {
      var _this = this;
      var params = this.getWithDefault('params', []);

      function callbackHandler(promise) {
        set(_this, 'promise', promise);
      }

      var actionArguments = ['action', callbackHandler].concat(_toConsumableArray(params));

      this.sendAction.apply(this, _toConsumableArray(actionArguments));
      set(this, 'textState', 'pending');

      // If this is part of a form, it will perform an HTML form
      // submission without returning false to prevent action bubbling
      return false;
    },

    text: _ember['default'].computed('textState', 'default', 'pending', 'resolved', 'fulfilled', 'rejected', function () {
      return getWithDefault(this, this.textState, get(this, 'default'));
    }),

    resetObserver: _ember['default'].observer('textState', 'reset', function () {
      var states = ['resolved', 'rejected', 'fulfilled'];
      var found = false;
      var textState = get(this, 'textState');

      for (var idx = 0; idx < states.length && !found; idx++) {
        found = textState === states[idx];
      }

      if (get(this, 'reset') && found) {
        set(this, 'textState', 'default');
      }
    }),

    handleActionPromise: _ember['default'].observer('promise', function () {
      var _this = this;
      var promise = get(this, 'promise');
      if (!promise) {
        return;
      }
      promise.then(function () {
        if (!_this.isDestroyed) {
          set(_this, 'textState', 'fulfilled');
        }
      })['catch'](function () {
        if (!_this.isDestroyed) {
          set(_this, 'textState', 'rejected');
        }
      });
    }),

    setUnknownProperty: function setUnknownProperty(key, value) {
      if (key === 'resolved') {
        _ember['default'].deprecate("The 'resolved' property is deprecated. Please use 'fulfilled'", false);
        key = 'fulfilled';
      }

      this[key] = null;
      this.set(key, value);
    },

    _href: _ember['default'].computed('href', function () {
      var href = get(this, 'href');
      if (href) {
        return href;
      }

      var tagName = get(this, 'tagName').toLowerCase();
      if (tagName === 'a' && href === undefined) {
        return '';
      }
    })
  });

  // Ember 1.13.6 will deprecate specifying `positionalParams` on the
  // instance in favor of class level property
  //
  // Having both defined keeps us compatible with Ember 1.13+ (all patch versions)
  ButtonComponent.reopenClass(positionalParams);

  exports['default'] = ButtonComponent;
});