define('ember-calendar/models/time-zone-option', ['exports', 'moment', 'ember'], function (exports, _moment, _ember) {
  'use strict';

  exports['default'] = _ember['default'].Object.extend({
    value: null,

    description: _ember['default'].computed('value', function () {
      return this.get('_title') + '\n            (' + this.get('_offset') + '\n            ' + this.get('abbreviation') + ')';
    }),

    abbreviation: _ember['default'].computed('value', function () {
      return (0, _moment['default'])().tz(this.get('value')).format('z');
    }),

    _title: _ember['default'].computed('value', function () {
      return this.get('value').replace('_', ' ');
    }),

    _offset: _ember['default'].computed('value', function () {
      return (0, _moment['default'])().tz(this.get('value')).format('Z');
    })
  });
});