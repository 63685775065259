define('ember-calendar/components/as-calendar', ['exports', 'jstz', 'ember', 'ember-calendar/models/component-calendar', 'ember-component-inbound-actions/inbound-actions'], function (exports, _jstz, _ember, _emberCalendarModelsComponentCalendar, _emberComponentInboundActionsInboundActions) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend(_emberComponentInboundActionsInboundActions['default'], {
    classNameBindings: [':as-calendar'],
    tagName: 'section',

    dayEndingTime: '22:00',
    dayStartingTime: '8:00',
    defaultOccurrenceDuration: '1:00',
    defaultOccurrenceTitle: 'New event',
    defaultTimeZoneQuery: '',
    isEditing: true,
    model: null,
    occurrences: null,
    showHeader: true,
    showTimeZoneSearch: true,
    startingDate: null,
    timeSlotDuration: '00:30',
    timeSlotHeight: 20,
    timeZone: _jstz['default'].determine().name(),
    title: null,

    _initializeModel: _ember['default'].on('init', function () {
      this.set('model', _emberCalendarModelsComponentCalendar['default'].create({ component: this }));
    }),

    actions: {
      changeTimeZone: function changeTimeZone(timeZone) {
        this.set('timeZone', timeZone);
      },

      addOccurrence: function addOccurrence(time) {
        var occurrence = this.get('model').createOccurrence({
          startsAt: time.toDate()
        });

        this.attrs['onAddOccurrence'](occurrence.get('content'));
      },

      onNavigateWeek: function onNavigateWeek(index) {
        if (this.attrs['onNavigateWeek']) {
          this.attrs['onNavigateWeek'](index);
        }
      }
    }
  });
});