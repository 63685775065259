define('ember-calendar/components/as-calendar/header', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: [':as-calendar-header'],
    tagName: 'header',

    isInCurrentWeek: _ember['default'].computed.oneWay('model.isInCurrentWeek'),
    model: null,
    title: '',

    actions: {
      navigateWeek: function navigateWeek(index) {
        this.get('model').navigateWeek(index);

        if (this.attrs['onNavigateWeek']) {
          this.attrs['onNavigateWeek'](index);
        }
      },

      goToCurrentWeek: function goToCurrentWeek() {
        this.get('model').goToCurrentWeek();
      }
    }
  });
});