define('ember-calendar/models/time-slot', ['exports', 'moment', 'ember'], function (exports, _moment, _ember) {
  'use strict';

  var TimeSlot = _ember['default'].Object.extend({
    duration: null,
    time: null,
    timeZone: null,

    endingTime: _ember['default'].computed('time', 'duration', function () {
      return _moment['default'].duration(this.get('time')).add(this.get('duration'));
    }),

    day: _ember['default'].computed('timeZone', function () {
      return (0, _moment['default'])().tz(this.get('timeZone')).startOf('day');
    }),

    value: _ember['default'].computed('day', 'time', function () {
      return (0, _moment['default'])(this.get('day')).add(this.get('time'));
    }),

    endingValue: _ember['default'].computed('day', 'endingTime', function () {
      return (0, _moment['default'])(this.get('day')).add(this.get('endingTime'));
    }),

    isInRange: function isInRange(startingTime, endingTime) {
      var value = this.get('value');
      var day = this.get('day');

      return value >= (0, _moment['default'])(day).add(startingTime) && this.get('endingValue') <= (0, _moment['default'])(day).add(endingTime);
    },

    next: function next() {
      var duration = this.get('duration');

      return TimeSlot.create({
        timeZone: this.get('timeZone'),
        time: _moment['default'].duration(this.get('time')).add(duration),
        duration: duration
      });
    }
  });

  TimeSlot.reopenClass({
    buildDay: function buildDay(options) {
      var timeSlots = _ember['default'].A();

      var currentTimeSlot = this.create({
        timeZone: options.timeZone,
        time: options.startingTime,
        duration: options.duration
      });

      while (currentTimeSlot.isInRange(options.startingTime, options.endingTime)) {
        timeSlots.pushObject(currentTimeSlot);
        currentTimeSlot = currentTimeSlot.next();
      }

      return timeSlots;
    }
  });

  exports['default'] = TimeSlot;
});