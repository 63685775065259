define('ember-calendar/components/as-calendar/timetable', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: [':as-calendar-timetable'],
    tagName: 'section',

    days: _ember['default'].computed.oneWay('model.days'),
    model: null,
    timeSlotHeight: null,
    timeSlots: _ember['default'].computed.oneWay('model.timeSlots'),
    contentComponent: null,
    dayWidth: _ember['default'].computed.oneWay('contentComponent.dayWidth'),
    referenceElement: _ember['default'].computed.oneWay('contentComponent.element'),

    labeledTimeSlots: _ember['default'].computed('timeSlots.[]', function () {
      return this.get('timeSlots').filter(function (_, index) {
        return index % 2 === 0;
      });
    }),

    timeSlotLabelListStyle: _ember['default'].computed('timeSlotHeight', function () {
      var timeSlotHeight = this.get('timeSlotHeight');

      return ('margin-top: -' + timeSlotHeight + 'px;\n             line-height: ' + timeSlotHeight * 2 + 'px;').htmlSafe();
    }),

    timeSlotLabelStyle: _ember['default'].computed('timeSlotHeight', function () {
      return ('height: ' + 2 * this.get('timeSlotHeight') + 'px;').htmlSafe();
    })
  });
});