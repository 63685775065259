/* globals jstz */
// https://github.com/rwjblue/ember-cli-pretender

define('jstz', [], function() {
  'use strict';

  return {
    'default': jstz
  };
});
