define('ember-calendar/models/calendar', ['exports', 'ember', 'moment', 'ember-calendar/models/time-slot', 'ember-calendar/models/day', 'ember-calendar/models/occurrence-proxy'], function (exports, _ember, _moment, _emberCalendarModelsTimeSlot, _emberCalendarModelsDay, _emberCalendarModelsOccurrenceProxy) {
  'use strict';

  exports['default'] = _ember['default'].Object.extend({
    dayEndingTime: null,
    dayStartingTime: null,
    occurrences: null,
    startingDate: null,
    timeSlotDuration: null,
    timeZone: null,
    occurrencePreview: null,

    isInCurrentWeek: _ember['default'].computed('week', '_currentWeek', function () {
      return this.get('week').isSame(this.get('_currentWeek'));
    }),

    timeSlots: _ember['default'].computed('timeZone', 'dayStartingTime', 'dayEndingTime', 'timeSlotDuration', function () {
      return _emberCalendarModelsTimeSlot['default'].buildDay({
        timeZone: this.get('timeZone'),
        startingTime: this.get('dayStartingTime'),
        endingTime: this.get('dayEndingTime'),
        duration: this.get('timeSlotDuration')
      });
    }),

    days: _ember['default'].computed(function () {
      return _emberCalendarModelsDay['default'].buildWeek({ calendar: this });
    }),

    week: _ember['default'].computed('startingTime', 'timeZone', function () {
      return (0, _moment['default'])(this.get('startingTime')).tz(this.get('timeZone')).startOf('isoWeek');
    }),

    _currentWeek: _ember['default'].computed('timeZone', function () {
      return (0, _moment['default'])().tz(this.get('timeZone')).startOf('isoWeek');
    }),

    initializeCalendar: _ember['default'].on('init', function () {
      if (this.get('startingTime') == null) {
        this.goToCurrentWeek();
      }
    }),

    createOccurrence: function createOccurrence(options) {
      var content = _ember['default'].merge({
        endsAt: (0, _moment['default'])(options.startsAt).add(this.get('defaultOccurrenceDuration')).toDate(),

        title: this.get('defaultOccurrenceTitle')
      }, options);

      return _emberCalendarModelsOccurrenceProxy['default'].create({
        calendar: this,
        content: _ember['default'].Object.create(content)
      });
    },

    navigateWeek: function navigateWeek(index) {
      this.set('startingTime', (0, _moment['default'])(this.get('startingTime')).add(index, 'weeks'));
    },

    goToCurrentWeek: function goToCurrentWeek() {
      this.set('startingTime', (0, _moment['default'])());
    }
  });
});