define('ember-cli-form-data/mixins/form-data-adapter', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = _ember['default'].Mixin.create({
    // Overwrite to change the request types on which Form Data is sent
    formDataTypes: ['POST', 'PUT', 'PATCH'],

    ajaxOptions: function ajaxOptions(url, type, options) {
      var data;

      if (options && 'data' in options) {
        data = options.data;
      }

      var hash = this._super.apply(this, arguments);

      if (typeof FormData !== 'undefined' && data && this.formDataTypes.contains(type)) {
        var formData = new FormData();
        var root = _ember['default'].keys(data)[0];

        _ember['default'].keys(data[root]).forEach(function (key) {
          if (typeof data[root][key] !== 'undefined') {
            formData.append(root + "[" + key + "]", data[root][key]);
          }
        });

        hash.processData = false;
        hash.contentType = false;
        hash.data = formData;
      }

      return hash;
    }
  });
});