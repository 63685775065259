define('ember-calendar/macros/computed-moment', ['exports', 'moment', 'ember'], function (exports, _moment, _ember) {
  'use strict';

  exports['default'] = function (dependentKey) {
    return _ember['default'].computed(dependentKey, {
      get: function get() {
        var dependentValue = this.get(dependentKey);

        if (dependentValue != null) {
          return (0, _moment['default'])(dependentValue);
        } else {
          return null;
        }
      },

      set: function set(_, value) {
        if (value != null) {
          this.set(dependentKey, value.toDate());
        } else {
          this.set(dependentKey, null);
        }

        return value;
      }
    });
  };
});