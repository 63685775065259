/* globals interact */
// https://github.com/rwjblue/ember-cli-pretender

define('interact', [], function() {
  'use strict';

  return {
    'default': interact
  };
});
