define('ember-shepherd/initializers/tour', ['exports'], function (exports) {
  'use strict';

  exports.initialize = initialize;

  function initialize(application) {
    application.inject('route', 'tour', 'service:tour');
    application.inject('controller', 'tour', 'service:tour');
  }

  exports['default'] = {
    name: 'tour',
    initialize: initialize
  };
});