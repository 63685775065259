define('ember-shepherd/instance-initializers/tour', ['exports'], function (exports) {
  'use strict';

  exports.initialize = initialize;

  function initialize(instance) {
    var service = instance.lookup('service:tour');
    var appController = instance.lookup('controller:application');
    service.set('applicationController', appController);
  }

  exports['default'] = {
    name: 'tour',
    initialize: initialize
  };
});