define('ember-calendar/models/occurrence-proxy', ['exports', 'ember', 'moment', 'ember-calendar/macros/computed-moment', 'ember-calendar/models/day'], function (exports, _ember, _moment, _emberCalendarMacrosComputedMoment, _emberCalendarModelsDay) {
  'use strict';

  var OccurrenceProxy = _ember['default'].Object.extend(_ember['default'].Copyable, {
    calendar: null,
    content: null,
    endingTime: (0, _emberCalendarMacrosComputedMoment['default'])('content.endsAt'),
    startingTime: (0, _emberCalendarMacrosComputedMoment['default'])('content.startsAt'),
    title: _ember['default'].computed.oneWay('content.title'),

    duration: _ember['default'].computed('startingTime', 'endingTime', function () {
      return _moment['default'].duration(this.get('endingTime').diff(this.get('startingTime')));
    }),

    day: _ember['default'].computed('startingTime', 'calendar', function () {
      return _emberCalendarModelsDay['default'].create({
        calendar: this.get('calendar'),
        offset: this.get('startingTime').isoWeekday() - 1
      });
    }),

    copy: function copy() {
      return OccurrenceProxy.create({
        calendar: this.get('calendar'),

        content: _ember['default'].Object.create({
          startsAt: this.get('content.startsAt'),
          endsAt: this.get('content.endsAt'),
          title: this.get('content.title')
        })
      });
    }
  });

  exports['default'] = OccurrenceProxy;
});