define('ember-calendar/components/as-calendar/timetable/occurrence', ['exports', 'ember', 'moment', 'interact', 'ember-calendar/components/as-calendar/occurrence'], function (exports, _ember, _moment, _interact, _emberCalendarComponentsAsCalendarOccurrence) {
  'use strict';

  exports['default'] = _emberCalendarComponentsAsCalendarOccurrence['default'].extend({
    classNameBindings: [':as-calendar-occurrence--timetable'],

    timetable: null,
    isInteracting: false,
    isDraggable: true,
    isResizable: true,
    isRemovable: true,
    dayWidth: _ember['default'].computed.oneWay('timetable.dayWidth'),
    referenceElement: _ember['default'].computed.oneWay('timetable.referenceElement'),

    _calendar: _ember['default'].computed.oneWay('model.calendar'),
    _dayEndingTime: _ember['default'].computed.oneWay('day.endingTime'),
    _dragBottomDistance: null,
    _dragTopDistance: null,
    _dragVerticalOffset: null,
    _preview: _ember['default'].computed.oneWay('_calendar.occurrencePreview'),

    _setupInteractable: _ember['default'].on('didInsertElement', function () {
      var _this = this;

      var interactable = (0, _interact['default'])(this.$()[0]).on('mouseup', function (event) {
        _ember['default'].run(_this, _this._mouseUp, event);
      });

      if (this.get('isResizable')) {
        interactable.resizable({
          edges: { bottom: '.as-calendar-occurrence__resize-handle' },

          onstart: function onstart(event) {
            _ember['default'].run(_this, _this._resizeStart, event);
          },

          onmove: function onmove(event) {
            _ember['default'].run(_this, _this._resizeMove, event);
          },

          onend: function onend(event) {
            _ember['default'].run(_this, _this._resizeEnd, event);
          }
        });
      }

      if (this.get('isDraggable')) {
        interactable.draggable({
          onstart: function onstart(event) {
            _ember['default'].run(_this, _this._dragStart, event);
          },

          onmove: function onmove(event) {
            _ember['default'].run(_this, _this._dragMove, event);
          },

          onend: function onend(event) {
            _ember['default'].run(_this, _this._dragEnd, event);
          }
        });
      }
    }),

    _teardownInteractable: _ember['default'].on('willDestroyElement', function () {
      (0, _interact['default'])(this.$()[0]).off();
    }),

    _resizeStart: function _resizeStart() {
      this.set('isInteracting', true);
      this.set('_calendar.occurrencePreview', this.get('model').copy());
    },

    _resizeMove: function _resizeMove(event) {
      var newDuration = _moment['default'].duration(Math.floor(event.rect.height / this.get('timeSlotHeight')) * this.get('computedTimeSlotDuration').as('ms'));

      var changes = {
        endsAt: (0, _moment['default'])(this.get('_startingTime')).add(newDuration).toDate()
      };

      this._validateAndSavePreview(changes);
    },

    _resizeEnd: function _resizeEnd() {
      this.attrs.onUpdate(this.get('content'), {
        endsAt: this.get('_preview.content.endsAt')
      });

      this.set('isInteracting', false);
      this.set('_calendar.occurrencePreview', null);
    },

    _dragStart: function _dragStart() {
      var $this = this.$();
      var $referenceElement = _ember['default'].$(this.get('referenceElement'));

      this.set('isInteracting', true);
      this.set('_calendar.occurrencePreview', this.get('model').copy());
      this.set('_dragVerticalOffset', 0);
      this.set('_dragTopDistance', $referenceElement.offset().top - $this.offset().top);

      this.set('_dragBottomDistance', $referenceElement.offset().top + $referenceElement.height() - ($this.offset().top + $this.height()));
    },

    _dragMove: function _dragMove(event) {
      this.set('_dragVerticalOffset', this.get('_dragVerticalOffset') + event.dy);

      var dragTimeSlotOffset = this._dragTimeSlotOffset(event);
      var dragDayOffset = this._dragDayOffset(event);
      var startsAt = (0, _moment['default'])(this.get('_startingTime')).add(dragTimeSlotOffset).add(dragDayOffset);

      var changes = {
        startsAt: startsAt.toDate(),
        endsAt: (0, _moment['default'])(startsAt).add(this.get('_duration')).toDate()
      };

      this._validateAndSavePreview(changes);
    },

    _dragTimeSlotOffset: function _dragTimeSlotOffset() {
      var verticalDrag = this._clamp(this.get('_dragVerticalOffset'), this.get('_dragTopDistance'), this.get('_dragBottomDistance'));

      return _moment['default'].duration(Math.floor(verticalDrag / this.get('timeSlotHeight')) * this.get('computedTimeSlotDuration'));
    },

    _dragDayOffset: function _dragDayOffset(event) {
      var $referenceElement = _ember['default'].$(this.get('referenceElement'));

      var offsetX = this._clamp(event.pageX - $referenceElement.offset().left, 0, $referenceElement.width() - 1);

      return _moment['default'].duration(Math.floor(offsetX / this.get('dayWidth')) - this.get('day.offset'), 'days');
    },

    _dragEnd: function _dragEnd() {
      this.attrs.onUpdate(this.get('content'), {
        startsAt: this.get('_preview.content.startsAt'),
        endsAt: this.get('_preview.content.endsAt')
      });

      this.set('isInteracting', false);
      this.set('_calendar.occurrencePreview', null);
      this.set('_dragVerticalOffset', null);
      this.set('_dragTopDistance', null);
      this.set('_dragBottomDistance', null);
    },

    _mouseUp: function _mouseUp() {
      _ember['default'].$(document.documentElement).css('cursor', '');
    },

    _validateAndSavePreview: function _validateAndSavePreview(changes) {
      if (this._validatePreviewChanges(changes)) {
        this.attrs.onUpdate(this.get('_preview.content'), changes);
      }
    },

    _validatePreviewChanges: function _validatePreviewChanges(changes) {
      var newPreview = this.get('_preview').copy();

      newPreview.get('content').setProperties(changes);

      return newPreview.get('startingTime') >= newPreview.get('day.startingTime') && newPreview.get('endingTime') <= newPreview.get('day.endingTime') && newPreview.get('duration') >= this.get('computedTimeSlotDuration');
    },

    _clamp: function _clamp(number, min, max) {
      return Math.max(min, Math.min(number, max));
    },

    actions: {
      remove: function remove() {
        this.attrs.onRemove(this.get('content'));
      }
    }
  });
});